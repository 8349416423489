<template>
  <v-container class="pa-0">
    <!-- 選択肢(編集) -->
    <v-container>
      <v-row align="center">
        <v-col cols="auto">
          <span class="text-h3">{{ subtitle }}</span>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
            </template>
            <span>先頭のものが初期選択になります</span>
          </v-tooltip>
        </v-col>
        <v-spacer />
        <v-col class="pa-0">
          <v-select
            v-model="selectedMenuId"
            :items="tmpMenus"
            item-value="id"
            item-text="name"
            label="選択後に追加を押してください"
          />
          <!-- <v-combobox
            ref="menuCombo"
            v-model="selectedMenuId"
            :items="tmpMenus"
            item-value="id"
            item-text="name"
            label="選択後に追加を押してください"
            clearable
          /> -->
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="addChoice">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span class="text-h5">追加</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :show-rows-border="false"
        :items="tmpItems"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
        class="mt-4 elevation-1 mytable row-height-100"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="index" :class="h.class">
                <span class="primary--text text-h5">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:[`item.name`]="props">
          <v-text-field
            v-if="props.item.refMenuId == null"
            v-model="props.item.name"
            single-line
            clearable
            background-color="grey lighten-4"
            class="text-h5"
          />
          <span v-else class="text-h5">{{ props.item.name }}</span>
        </template>
        <template v-slot:[`item.amount`]="props">
          <v-text-field
            v-if="props.item.refMenuId == null"
            v-model.number="props.item.amount"
            type="number"
            :maxlength="7"
            background-color="grey lighten-4"
            class="text-h5"
          />
          <span v-else class="text-h5">{{ props.item.amount }}</span>
        </template>
        <template v-slot:[`item.provideStatus`]="props">
          <v-select
            v-if="props.item.refMenuId == null"
            v-model="props.item.provideStatusObj"
            :items="tmpProvideStatusList"
            item-value="id"
            item-text="label"
            :return-object="true"
            background-color="grey lighten-4"
            class="text-h5"
          />
          <span v-else class="text-h5">{{
            props.item.provideStatusObj.label
          }}</span>
        </template>
        <template v-slot:[`item.delete`]="props">
          <!-- <v-icon @click="removeChoice(props.item.index)">mdi-close</v-icon> -->
          <v-icon @click="del(props.item)">mdi-close</v-icon>
        </template>
      </v-data-table>

      <v-row class="mt-4" justify="end" align="center">
        <v-col cols="auto">
          <v-btn @click="cancel">
            <span class="text-h5">元に戻す</span>
          </v-btn>
          <v-btn color="primary" @click="save">
            <span class="text-h5">更新する</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- 部品 -->
    <v-container>
      <v-dialog v-model="delInfo.show" max-width="300" persistent>
        <v-card>
          <v-card-title> 削除してよろしいですか? </v-card-title>
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="delInfo.show = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="removeChoiceById(delInfo.id)">
              削除する
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="confirmInfo.show"
        :max-width="confirmInfo.maxWidth"
        persistent
      >
        <v-card>
          <v-card-title v-html="confirmInfo.title" />
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="confirmInfo.doCancel">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="confirmInfo.doPositive">
              {{ confirmInfo.textPositive }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
const _cloneDeep = require("lodash.clonedeep");
import { createUuid } from "../../../../util/commonUtil";
import { CHOICE_ID_DEFAULT, PROVIDE_STATUS } from "../../../../constants";

// 提供状態
const PROVIDE_STATUS_LIST = PROVIDE_STATUS.map((s) => {
  return {
    id: s.id,
    label: s.name,
  };
});

// 選択肢 validate
const VALID_CHOICE = {
  OK: 0,
  NG_ZERO_LENGTH: 10,
  NG_NAME: 11,
  NG_ZERO_YEN: 12,
};

export default {
  name: "SortableToggleListEdit",
  components: {},
  props: {
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    datas: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    menus: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    handleToggleMode: {
      type: Function,
      required: true,
    },
    handleUpdateChoices: {
      type: Function,
      required: true,
    },
  },
  computed: {},
  data: () => ({
    tmpItems: [],

    tmpProvideStatusList: [],
    // 選択メニュー
    // inputMenuText: null,
    selectedMenuId: null,
    tmpMenus: [], // 選択肢の表示候補 (master そのまま)
    headers: [
      {
        text: "名前",
        value: "name",
        align: "start",
        sortable: false,
      },
      {
        text: "金額",
        value: "amount",
        align: "start",
        sortable: false,
        class: "width15",
      },
      {
        text: "提供状態",
        value: "provideStatus",
        align: "start",
        sortable: false,
        class: "width15",
      },
      {
        text: "税有無",
        value: "taxInclude", //税込 or 税抜
        align: "start",
        sortable: false,
        class: "width10",
      },
      {
        text: "税区分",
        value: "taxKbn", //8%軽減 or ...
        align: "start",
        sortable: false,
        class: "width10",
      },
      {
        text: "削除",
        value: "delete",
        align: "start",
        sortable: false,
        class: "width10",
      },
    ],
    delInfo: { show: false, index: -1 },
    confirmInfo: {
      show: false,
      maxWidth: 300,
      textPositive: "",
      textNegative: "",
      doPositive: () => {},
      doCancel: () => {},
    },
    // rules
    max25chars: (v) => v.length <= 25 || "Input too long!",
    numberRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return "Number has to be between 0 and 999";
    },
    rules: {
      counter7: (value) => value.length <= 7 || "Max 7 characters",
      numberOnly: (v) => !isNaN(parseFloat(v)) || "Number only",
    },
    // snack
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  watch: {
    datas: {
      handler: function (newValue) {
        const items = _cloneDeep(newValue);
        const newItems = items.map((item) => {
          const provideStatusObj = PROVIDE_STATUS_LIST.find(
            (v) => v.id == item.provideStatus
          );
          item.provideStatusObj = provideStatusObj ?? {};
          return item;
        });
        this.tmpItems = newItems;

        // 空の場合は空行追加
        if (!items || items.length == 0) {
          this.addEmptyChoice(CHOICE_ID_DEFAULT);
        }
      },
      deep: true,
      immediate: true, // 初回読込時も動作させる
    },
  },
  async mounted() {
    console.log("@menus(mounted)", this.menus);
    let tmpMenus = [];
    tmpMenus.push({
      id: CHOICE_ID_DEFAULT,
      name: "新規作成",
      amount: 0,
    });
    tmpMenus.push(...this.menus);
    console.log("@tmpMenus(mounted)", tmpMenus);
    this.tmpMenus = tmpMenus;

    this.selectedMenuId = this.tmpMenus[0].id;
    this.tmpProvideStatusList = PROVIDE_STATUS_LIST;
  },
  methods: {
    // 削除 押下
    del: function (choice) {
      console.log(`del: ${JSON.stringify(choice)}`);

      // 空データ(名前未入力データ)
      if (this.isEmptyChoice(choice)) {
        this.removeChoiceById(choice.id);
        return;
      }

      // 確認ダイアログ
      this.delInfo = { show: true, id: choice.id };
    },
    removeChoiceById(id) {
      console.log(`removeChoiceById: ${JSON.stringify(id)}`);
      this.tmpItems = this.tmpItems.filter((item) => item.id != id);
      console.log(`removeChoiceById(after): ${JSON.stringify(this.tmpItems)}`);

      this.delInfo = { show: false };
    },
    //=========================
    // 選択肢
    //=========================
    addEmptyChoice(choiceId) {
      console.log(`addEmptyChoice => ${choiceId}`);
      const emptyChoice = this.createEmptyChoice(choiceId);
      this.tmpItems.push(emptyChoice);
    },
    createEmptyChoice(choiceId) {
      // 提供状態
      const provideStatusObj = PROVIDE_STATUS_LIST[0];
      return {
        id: `${choiceId}`,
        name: "",
        text: "",
        amount: 0,
        provideStatus: provideStatusObj.id,
        provideStatusObj,
        taxInclude: "税込",
        taxKbn: "8%軽減",
      };
    },
    createRefMenuChoice(choiceId, menu) {
      // 提供状態
      const provideStatusObj = PROVIDE_STATUS_LIST.find(
        (v) => v.id == menu.provideStatus
      );

      return {
        id: `${choiceId}`,
        refMenuId: menu.id,
        name: menu.name,
        text: "",
        amount: menu.amountBaseEx, // 割引後の金額
        provideStatus: menu.provideStatus,
        provideStatusObj,
        taxInclude: "税込",
        taxKbn: "8%軽減",
      };
    },
    // 追加 押下
    addChoice() {
      // コンボの場合
      // // 選択値
      // const inputMenuText = this.$refs.menuCombo;
      // const test = this.$refs.menuCombo.internalSearch;
      // console.log("@addChoice", inputMenuText, this.selectedMenuId, test);
      //
      // // 選択値リセット
      // // this.inputMenuText = null;
      // // this.selectedMenuId = null;
      // // this.$refs.menuCombo.reset(); // 一度フォーカスアウトすると消えるが、そのままボタン押下だと消えない
      //

      // 空行(手入力) or 選択メニュー を行追加
      console.log("@addChoice", this.selectedMenuId);
      const choiceId = createUuid();
      let choice = null;
      if (this.selectedMenuId === CHOICE_ID_DEFAULT) {
        choice = this.createEmptyChoice(choiceId);
      } else {
        const selectedMenu = this.tmpMenus.find(
          (menu) => menu.id === this.selectedMenuId
        );
        console.log("selectedMenu", selectedMenu);
        choice = this.createRefMenuChoice(choiceId, selectedMenu);
      }
      this.tmpItems.push(choice);
    },
    save() {
      console.log(`items(save)`, this.tmpItems);
      // validate
      const valid = this.validateChoices();
      if (valid !== VALID_CHOICE.OK) {
        this.snack = true;
        this.snackColor = "error";
        let snackText = "";
        if (valid === VALID_CHOICE.NG_ZERO_LENGTH) {
          snackText = "選択肢が空です";
        } else if (valid === VALID_CHOICE.NG_NAME) {
          snackText = "名前は必須です";
        } else if (valid === VALID_CHOICE.NG_ZERO_YEN) {
          snackText = "0円の選択肢は必須です";
        }
        this.snackText = snackText;
        return;
      }

      // 確認ダイアログ
      this.confirmInfo = {
        show: true,
        maxWidth: 500,
        title: "選択肢を更新します。<br/>よろしいですか？",
        textPositive: "更新する",
        textNegative: "とじる",
        doPositive: () => {
          // 更新用データ作成
          console.log(`items(before)`, this.tmpItems);
          const items = this.createItemsForUpdate(this.tmpItems);
          console.log(`items(after)`, this.tmpItems);

          this.handleUpdateChoices(items); // 反映 (子->親)
          this.handleToggleMode(); // 表示モードへ
        },
        doCancel: () => {
          this.confirmInfo.show = false; // とじる
        },
      };
    },
    cancel() {
      // 確認ダイアログ
      this.confirmInfo = {
        show: true,
        maxWidth: 500,
        title: "編集中の内容は破棄されます。<br/>よろしいですか？",
        textPositive: "破棄する",
        textNegative: "とじる",
        doPositive: () => {
          this.handleToggleMode(); // 表示モードへ
        },
        doCancel: () => {
          this.confirmInfo.show = false; // とじる
        },
      };
    },
    validateChoices() {
      // 空データチェック
      // - 1件以上
      // - [必須] 名前
      // - [必須] 0円選択肢
      const targetItems = this.tmpItems;

      // 1件以上
      if (targetItems.length == 0) {
        return VALID_CHOICE.NG_ZERO_LENGTH;
      }

      // 名前
      if (targetItems.filter((c) => this.isEmptyChoice(c)).length != 0) {
        return VALID_CHOICE.NG_NAME;
      }

      // 0円選択肢
      if (targetItems.filter((c) => this.isZeroYenChoice(c)).length == 0) {
        return VALID_CHOICE.NG_ZERO_YEN;
      }

      return VALID_CHOICE.OK;
    },
    isEmptyChoice(choice) {
      // 名前未入力
      return !choice.name;
    },
    isZeroYenChoice(choice) {
      // 0円選択肢
      return choice.amount === 0;
    },
    createItemsForUpdate(tmpItems) {
      const items = tmpItems.map((item) => {
        // 手入力
        if (!item.refMenuId) {
          item.provideStatus = item.provideStatusObj.id;
        }
        delete item.provideStatusObj; // 不要
        return item;
      });
      return items;
    },
  },
};
</script>
<style>
@import "../../../../css/sortableList.css";
</style>
